<template>
  <div class="uploadFile" @change="change">
    <div class="box-white">
      <div class="box-title-uplaod">
        <span class="text">Choisissez votre date</span>
      </div>
      <div class="box-filter-upload">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="year"
            :options="ListYear"
            @change="HandleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ" v-if="computedIsoCurenType">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="semaine"
            :options="ListWeek"
          ></b-form-select>
        </div>
        <!-- <div class="box-label-champ" v-if="computedThCurenType">
          <div class="label-box-style">
            <span class="title-tabel">Dépôt</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="depot"
            :options="getAllDepotForYearTh"
            value-field="depot"
            text-field="depot_number"
          ></b-form-select>
        </div> -->
      </div>
      <div class="box-title-uplaod mt-4">
        <span class="text">Importer</span>
      </div>
      <b-row align-h="center" v-if="cantUpload(this.$options.name)">
        <div class="container" @drop="handleFileDrop">
          <div class="file-wrapper">
            <input
              type="file"
              name="file-input"
              id="uploadInput"
              :class="{ display: this.getLoading }"
              @change="handleFileInput"
            />
            <div class="upload_label">
              <img
                src="../assets/Icon feather-upload.png"
                class="icon-upload"
              />
              <p class="drag-title mb-0">Drag & Drop to insert</p>
              <p class="drag-title mt-0 mb-0">or</p>
              <div class="btn-upload-file">Browse File</div>
            </div>
            <div class="upload_label">
              <p class=" drag-title" v-if="this.file">
                {{ this.file.name }}
              </p>
            </div>
          </div>
        </div>
      </b-row>
      <div class="file-exel-style"><span>*</span> Fichier Excel</div>
    </div>
    <!-- <div v-if="getError" class="error-upload">
      <ul v-if="getError.extra || getError.missing">
        <div v-if="getError.extra">
          <span class="title-error">Colonnes intruses :</span>
          <div>
            <span v-for="(e, index) in getError.extra" :key="index">
              {{ e }},
            </span>
          </div>
        </div>
        <div v-if="getError.missing">
          <span class="title-error">Colonnes manquantes :</span>
          <div>
            <span v-for="(e, index) in getError.missing" :key="index">
              {{ e }},
            </span>
          </div>
        </div>
      </ul>
      <span v-else>{{ getError }}</span>
    </div> -->
    <img src="../assets/Intersection1.png" class="footer-upload" />
    <loader v-if="this.getLoading" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Insertion-BDD',
  data() {
    return {
      file: null,
      year: null,
      semaine: null,
      ListYear: [],
      ListWeek: [],
      depot: null
    };
  },
  methods: {
    ...mapActions([
      'uploadFile',
      'featchDepotForYearTH',
      'uploadFileTh',
      'CheckuploadFileTh'
    ]),
    ...mapMutations(['SET_ERROR']),
    async change() {
      if (this.file !== null) {
        const payload = {
          annee: this.year,
          file: this.file,
          semaine: this.semaine
          // depot: this.depot
        };
        if (this.computedIsoCurenType) {
          if (await this.uploadFile(payload)) {
            var chaine = this.semaine.substr(1);
            localStorage.setItem(
              'Rapport-Filters',
              JSON.stringify({
                year: this.year,
                semaine: { value: parseInt(chaine), text: parseInt(chaine) },
                anomalie: [],
                type: null,
                month: 1,
                switchMonth: false
              })
            );
            sessionStorage.setItem('page-rapport', 1);
            this.$router.push('/rapport');
          }
        } else {
          const response = await this.CheckuploadFileTh(payload);
          if (response.succes) {
            // let mounthData = this.getAllDepotForYearTh.filter(
            //   item => item.depot == this.depot
            // );
            const response2 = await this.uploadFileTh({
              ...payload,
              force: 'no'
            });
            if (response2.succes) {
              localStorage.setItem(
                'Rapport-Filters-Th',
                JSON.stringify({
                  year: this.year,
                  depot: null,
                  anomalie: [],
                  type: null,
                  month: null,
                  filiale: null,
                  week: null,
                  statue: 'paye'
                })
              );
              // localStorage.setItem('UploadThProject', mounthData[0].month_id);
              sessionStorage.setItem('page-rapport-th', 1);
              this.$router.push('/rapportTh');
            }
          } else {
            if (this.getError.extra || this.getError.missing) {
              let extra = this.getError.extra
                ? "<div style='margin-right: 50px'> <h4><i>Colonnes intruses :</i></h4>"
                : '';
              if (this.getError.extra) {
                for (const [key, value] of Object.entries(
                  this.getError.extra
                )) {
                  extra = extra + '<li>' + value + '</li>';
                }
                extra = extra + '</div>';
              }
              let missing = this.getError.missing
                ? '<div><h4><i>Colonnes manquantes :</i></h4>'
                : '';
              if (this.getError.missing) {
                for (const [key, value] of Object.entries(
                  this.getError.missing
                )) {
                  missing = missing + '<li>' + value + '</li>';
                }
                missing = missing + '</div>';
              }

              let htmlContent =
                "<div style='display:flex'>" + extra + missing + '</div>';

              this.$swal.fire({
                background: 'rgb(245 245 252)',
                type: 'error',
                icon: 'error',
                html: htmlContent,
                allowOutsideClick: false,
                showCloseButton: true
              });
            } else {
              if (this.getError.empty == false) {
                let htmlMessage = `<div style="margin: auto;width: 50%;height: 250px;overflow-x: auto;" class="test" id="data-file">`;
                this.getError.data.forEach(
                  item => (htmlMessage = htmlMessage + '<li>' + item + '</li>')
                );

                htmlMessage =
                  htmlMessage +
                  `</div><span id='data-file-dup' style='display:none'>${this.getError.data}</span>`;
                htmlMessage =
                  htmlMessage +
                  `<button class="btn  btn-file" type="button" id="btn-file" name="btn-file" style="right: 140px;position: absolute;font-size: 31px;bottom: 10px;z-index: 50;" title='Télecharger la liste des dossiers dupliquer'>⬇️</button>`;

                this.$swal
                  .fire({
                    background: 'rgb(245 245 252)',
                    title:
                      'Êtes-vous sur de vouloir écraser les dossiers dupliquer (' +
                      this.getError.data.length +
                      ')',
                    type: 'warning',
                    icon: 'warning',
                    locale: 'fr',
                    allowOutsideClick: false,
                    showCloseButton: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    showDenyButton: false,
                    html: htmlMessage,
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non',
                    denyButtonText: 'BD',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonClass: 'btn btn-danger',
                    denyButtonClass: 'btn btn-info',
                    cancelButtonColor: '#d33',
                    willOpen: () => {
                      let item = document.getElementById('data-file-dup')
                        .textContent;
                      const text = item.replaceAll(',', '\n');
                      document
                        .getElementById('btn-file')
                        .addEventListener('click', function() {
                          var blob = new Blob([text], {
                            type: 'text/plain'
                          });
                          let link = document.createElement('a');
                          link.href = window.URL.createObjectURL(blob);
                          link.download = 'list des dossiers dupliquer .txt';
                          link.click();
                        });
                      document;
                    },
                    customClass: {
                      actions: 'my-actions',
                      cancelButton: 'order-2 ',
                      confirmButton: 'order-1',
                      denyButton: 'order-3'
                    }
                  })
                  .then(async result => {
                    let force = '';
                    if (result.isDismissed && result.dismiss != 'close')
                      force = 'no';
                    if (result.isDenied) force = 'db';
                    if (result.isConfirmed) force = 'oui';
                    if (force != '') {
                      const response2 = await this.uploadFileTh({
                        ...payload,
                        force: force
                      });
                      if (response2.succes) {
                        localStorage.setItem(
                          'Rapport-Filters-Th',
                          JSON.stringify({
                            year: this.year,
                            depot: null,
                            anomalie: [],
                            type: null,
                            month: null,
                            filiale: null,
                            week: null,
                            statue: 'paye'
                          })
                        );
                        // localStorage.setItem('UploadThProject', mounthData[0].month_id);
                        sessionStorage.setItem('page-rapport-th', 1);
                        this.$router.push('/rapportTh');
                      }
                    }
                  });
              }
            }
          }
        }
      }
    },
    handleFileDrop(e) {
      document.getElementById('uploadInput').value = '';
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        this.file = f;
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
    },
    HandleChangeYear() {
      if (this.computedIsoCurenType) {
        var totalWeek = moment({ year: this.year }).isoWeeksInYear();
        this.ListWeek = [];
        for (var week = 1; week <= totalWeek; week++) {
          this.ListWeek.push({ value: 's' + week, text: week });
        }
      }
      // else {
      //   this.featchDepotForYearTH(this.year);
      // }
    }
  },
  components: {
    loader: () => import('./Loader')
  },
  computed: {
    ...mapGetters([
      'getLoading',
      'getError',
      'getCurrentType',
      'getUserData',
      'cantUpload',
      'getAllDepotForYearTh'
    ]),
    computedIsoCurenType: function() {
      return (
        this.getCurrentType &&
        this.getCurrentType.value &&
        this.getCurrentType.value === 'iso'
      );
    },
    computedThCurenType: function() {
      return (
        this.getCurrentType &&
        this.getCurrentType.value &&
        this.getCurrentType.value === 'Th'
      );
    }
  },
  mounted() {
    var courantYear = moment().year();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.year = courantYear;
    if (this.computedIsoCurenType) {
      var totalWeek = moment({ year: courantYear }).isoWeeksInYear();
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.semaine = 's' + moment().isoWeek();
    }
    // {
    //   this.featchDepotForYearTH(this.year);
    // }
    else this.SET_ERROR(null);
  }
};
</script>

<style scoped lang="scss">
.uploadFile {
  position: relative;
  z-index: 3;
  width: 100%;
  text-align: center;
  background-color: #f5f4fa;
  border-radius: 19px;
  margin: 5px 25px 23px 22px;
  height: calc(100% - 74px);
  justify-content: center;
  align-items: center;
  display: flex;
  .footer-upload {
    width: 100%;
    bottom: 0px;
    position: absolute;
    z-index: 0;
  }
  .box-white {
    z-index: 50;
    width: fit-content;
    padding: 20px 20px 25px 0px;
    background: #ffffff;
    border-radius: 17px;
    width: 50%;
    top: -5%;
    position: relative;
    text-align: initial;
    .box-title-uplaod {
      position: relative;
      background-color: #f5f5ff;
      width: 215px;
      padding: 7px 23px;
      border-radius: 0px 18px 18px 0px;
      text-align: end;
      margin-bottom: 26px;
      .text {
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: #5d5d5d;
      }
    }
  }
  .container {
    height: 187px;
    width: 380px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px dashed #4d4bac;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 40px;
        position: relative;
        top: 32px;
        .drag-title {
          font-size: 15px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #4d4bac;
        }
        p {
          font-size: 15px;
        }
        .icon-upload {
          width: 33px;
          height: 33px;
          margin-bottom: 15px;
        }
        .btn-upload-file {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 400;
          width: 60%;
          border-radius: 29px;
          background-color: #4d4bac;
          padding: 11px 20px;
          border: 0;
          color: #fff;
          margin: auto;
        }
      }
    }
  }
  .file-exel-style {
    font-size: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #5d5d5d;
    position: relative;
    right: 23%;
    text-align: end;
    margin-top: 10px;
    span {
      font-weight: 500;
      color: #fe808b;
    }
  }
  .error-upload {
    position: absolute;
    width: 100%;
    font-size: 12px;
    text-align: center;
    bottom: -2%;
    right: 3%;
    z-index: 50;
    color: red;
    .title-error {
      font-weight: 600;
    }
  }
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
</style>
<style lang="scss">
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.test::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.test::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.test::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-actions {
  .swal2-confirm {
    background-color: #699d86;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 9px;
    padding: 7px 15px;
    border: none;
    width: 50px;
    margin-right: 5px;
    outline: none;
  }
  .swal2-cancel {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 9px;
    padding: 8px 15px !important;
    border: none;
    height: 33px;
    outline: none;
    width: 50px;
  }
  .swal2-deny {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 9px;
    padding: 8px 15px !important;
    border: none;
    height: 33px;
    outline: none;
    width: 50px;
  }
}
</style>
